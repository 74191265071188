import React, { useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

export default function Home({
  type,
  setType,
  generateEmailTemplate,
  isLoading,
  // mood,
  // setMood,
  wordLength,
  setWordLength,
  response,
  reset,
  email,
  setEmail,
}) {
  // const [email, setEmail] = useState("");
  const [userInfo] = useState(
    JSON.parse(localStorage.getItem("userInfo")) || {}
  );
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    // 'code-block',
    'list',
    'bullet',
    'indent',
    // 'link',
    // 'image',
  ];

  // const handleCodeBlock = () => {
  //   // Wrap the selected text in a code block
  //   const selectedText = window.getSelection().toString();
  //   const wrappedText = `\`\`\`\n${selectedText}\n\`\`\``;
  //   setEmail(email.replace(selectedText, wrappedText));
  // };

  return (
    <>
      <h1 className="text-3xl text-gray-700 font-bold mb-5 text-center">
        Elevate Efficiency with AI-Powered NoteSummarizer
      </h1>
      <h6 className="text-gray-500 text-sm text-center">
        NoteSummarizer harnesses the power of artificial intelligence to transform lengthy text into concise,
        <br />
        actionable summaries, streamlining your productivity and decision-making processes.
      </h6>
      <div className="mx-auto w-full lg:w-1/2 md:w-full sm:w-full mt-10">
        <ReactQuill
          theme="snow"
          value={email}
          onChange={(text) => setEmail(text)}
          formats={formats}
          modules={{
            toolbar: {
              container: [
                // [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                // ['code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                // ['link', 'image'],
              ],
              // handlers: {
              //   'code-block': handleCodeBlock,
              // },
            },
          }}
        />

        {/* <div className="code-preview">
          <SyntaxHighlighter language="javascript" style={vscDarkPlus}>
            {email}
          </SyntaxHighlighter>
        </div> */}
        <div className="flex flex-col">
          <div className="w-full text-center mt-1 flex gap-4 justify-center">
            <select
              value={type}
              onChange={(e) => setType(e.target.value)}
              className="form-select block px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option value="release notes">release notes</option>
              <option value="meeting notes">meeting notes</option>
              <option value="decision notes">decision notes</option>
              <option value="customer interaction notes">customer interaction notes</option>
              <option value="phone call notes">phone call notes</option>
              <option value="interview notes">interview notes</option>
              <option value="project planning notes">project planning notes</option>
            </select>
            <select
              value={wordLength}
              onChange={(e) => setWordLength(e.target.value)}
              className="form-select block px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option value="less than 100 words">&lt; 100 words</option>
              <option value="between 100 to 500 words">100 - 500 words</option>
              <option value="between 500 to 1000 words">
                500 - 1000 words
              </option>
              <option value="more than 1000 words but limit to 5000 words">
                1000 - 5000 words
              </option>
            </select>
            {/* <select
              value={mood}
              onChange={(e) => setMood(e.target.value)}
              className="form-select block px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option value="in happy mood">happy</option>
              <option value="in sad mood">sad</option>
              <option value="">neutral</option>
            </select> */}
          </div>
        </div>
        <div className="w-full text-center flex flex-col">
          <button
            disabled={!email}
            type="button"
            onClick={() => generateEmailTemplate()}
            className={`m-auto inline-block px-6 py-2.5 font-medium text-xs leading-tight uppercase rounded shadow-md transition duration-150 ease-in-out mt-1 ${
              email
                ? "bg-blue-600 text-white hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                : "bg-gray-100 cursor-no-drop"
            }`}
          >
            Generate
          </button>
          {userInfo.name && (
            <span>
              You&apos;ve used {userInfo.usage}{" "}
              {userInfo.usage > 1 ? "credits" : "credit"}.
            </span>
          )}
        </div>
      </div>
      {(response || isLoading) && (
        <div className="container mx-auto rounded-xl p-8 m-10 shadow border w-full lg:w-3/4 md:w-full sm:w-full mb-0">
          {isLoading ? (
            <div className="text-center">
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <span
              id="responseText"
              dangerouslySetInnerHTML={{ __html: response }}
            ></span>
          )}
        </div>
      )}
      {response && (
        <div className="w-full text-center">
          {/* <button type="button" onClick={() => copyToClipboard()} className="m-auto inline-block px-6 py-2.5 bg-white text-blue-600 font-medium text-xs leading-tight uppercase rounded shadow-md transition duration-150 ease-in-out mt-1 hover:bg-gray-100 hover:text-blue-700">Copy</button> */}
          <button
            type="button"
            onClick={() => reset()}
            className="m-auto inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mt-1"
          >
            Reset
          </button>
        </div>
      )}
    </>
  );
}
