import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import MyEmails from "./pages/MyEmails";
import Home from "./pages/Home";
import Login from "./components/Login";
import Register from "./components/Register";
import "./App.css";

// Backend config
const apiBaseURL = process.env.REACT_APP_API_URL;

function App() {
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState("");
  // const [originalResponse, setOriginalResponse] = useState("");
  const [authErrorMessage, setAuthErrorMessage] = useState("");
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("userInfo")) || {}
  );
  const [type, setType] = useState("release notes");
  const [mood, setMood] = useState("in happy mood");
  const [wordLength, setWordLength] = useState("between 500 to 1000 words");
  const [openedModal, setOpenedModal] = useState("");
  const [isContinue, setIsContinue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const generateEmailTemplate = async (token = null) => {
    // check if user is logged in or not. if not then show login popup else nothing
    if (!isContinue && userInfo.name == undefined) {
      setOpenedModal("login");
      setIsContinue(true);
      return false;
    }
    setIsContinue(false);

    setIsLoading(true);
    const prompt = `Please write ${type} from this text provided ${wordLength}: ${email} without additional details`;

    try {
      saveRequest(prompt, token);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  const registerUser = async (name, email, password) => {
    try {
      const response = await fetch(apiBaseURL + "register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          password,
          c_password: password,
          usage: 0,
        }),
      });
      const response_json = await response.json();
      setUserInfo(response_json.data);
      setOpenedModal("");
      setIsLoading(false);
      if (isContinue) {
        generateEmailTemplate(response_json.data.token);
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  const loginUser = async (email, password) => {
    try {
      const response = await fetch(apiBaseURL + "login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
      const response_json = await response.json();
      if (response_json.success) {
        setIsLoading(false);
        setUserInfo(response_json.data);
        setOpenedModal("");
        if (isContinue) {
          generateEmailTemplate(response_json.data.token);
        }
        setAuthErrorMessage("");
      } else {
        setIsLoading(false);
        setAuthErrorMessage(response_json.message);
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  const saveRequest = async (prompt, token = null) => {
    try {
      const response = await fetch(apiBaseURL + "save-request", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token || userInfo.token}`,
        },
        body: JSON.stringify({
          prompt,
        }),
      });
      const response_json = await response.json();
      setUserInfo(response_json.data);
      setResponse(
        response_json.data.template
          .replace(/\n/g, "<br />")
          .replace("<br /><br />", "")
      );
      // setOriginalResponse(response_json.data.template.replace(/\n/g, "\r\n"));
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const reset = () => {
    setEmail("");
    setResponse("");
  };

  // const copyToClipboard = () => {
  //   var textField = document.createElement("textarea");
  //   textField.innerText = originalResponse;
  //   document.body.appendChild(textField);
  //   textField.select();
  //   document.execCommand("copy");
  //   textField.remove();
  // };

  useEffect(() => {
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  }, [userInfo]);

  return (
    <BrowserRouter>
      <div className="flex flex-col justify-between h-screen">
        <div>
          {true && (
            <nav
              className="bg-white border-gray-200 px-2 sm:px-4 py-2.5"
              style={{ borderBottom: "1px solid #EAECF0" }}
            >
              <div className="container flex flex-wrap items-center justify-between mx-auto">
                <Link to="/" className="flex items-center">
                  <img
                    src="/logo.svg"
                    className="h-6 sm:h-6 mt-1"
                    style={{marginRight:'2px'}}
                    alt="NoteSummarizer Logo"
                  />
                  <span className="self-center text-xl font-semibold whitespace-nowrap">
                    NoteSummarizer
                  </span>
                </Link>
                <div className="md:block md:w-auto" id="navbar-default">
                  <ul className="flex flex-col mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
                    {userInfo.name ? (
                      <>
                        {/* <li>
                          <Link
                            to="/my-notes"
                            className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0"
                          >
                            My Notes ({userInfo.usage})
                          </Link>
                        </li> */}
                        <li>
                          <a
                            href="#"
                            onClick={() => {
                              setUserInfo({});
                              reset();
                            }}
                            className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0"
                            aria-current="page"
                          >
                            Logout
                          </a>
                        </li>
                      </>
                    ) : (
                      <li onClick={() => setOpenedModal("login")}>
                        <span
                          className="cursor-pointer block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0"
                          aria-current="page"
                        >
                          Login
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          )}
          <div className="container mx-auto rounded-xl p-8">
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <Home
                    type={type}
                    setType={setType}
                    generateEmailTemplate={generateEmailTemplate}
                    isLoading={isLoading}
                    mood={mood}
                    setMood={setMood}
                    wordLength={wordLength}
                    setWordLength={setWordLength}
                    response={response}
                    reset={reset}
                    email={email}
                    setEmail={setEmail}
                  />
                }
              />
              <Route exact path="/my-notes" element={<MyEmails />} />
            </Routes>
          </div>

          {openedModal === "login" && (
            <Login
              setOpenedModal={setOpenedModal}
              loginUser={loginUser}
              authErrorMessage={authErrorMessage}
              setIsContinue={setIsContinue}
            />
          )}
          {openedModal === "register" && (
            <Register
              setOpenedModal={setOpenedModal}
              registerUser={registerUser}
              setIsContinue={setIsContinue}
            />
          )}
        </div>
        <footer className="footer bg-white relative pt-1">
          <div className="container mx-auto px-6">
            <div className="border-t-2 border-gray-300 flex flex-col items-center">
              {/* <div className="sm:w-2/3 text-center py-2">
              <p className="text-sm mb-2">
                Developed by <a className="text-blue-700 font-bold" href="https://twitter.com/harshdoshi73" target="_blank">@harshdoshi73</a>
              </p>
            </div> */}
            </div>
          </div>
          {/*<a
            href={
              "https://www.producthunt.com/posts/ai-email-writer?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-ai&#0045;email&#0045;writer"
            }
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=377973&theme=light"
              alt={
                "AI&#0032;Email&#0032;Writer - Effortless&#0032;email&#0032;creation&#0032;with&#0032;GPT&#0045;3 | Product Hunt"
              }
              className="h-14 w-64 py-2 m-auto"
              width="250"
              height="54"
            />
            </a>*/}
        </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
